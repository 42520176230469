import Vue from 'vue';
import { ResultMixin, PrivilegeMixin } from '@/mixins';
import { mapGetters, mapMutations } from 'vuex';
import { SUCCESS_MUTE } from '@/constant/config';
import { formatStringDate, halfYearAgoDatetime, oneWeekAgoDatetime } from '@/helpers/date';
import { createModal } from '@/helpers/modal';
import { getCurrencyScale } from '@/helpers/scale';
import _ from 'lodash-es';

export default Vue.extend({
  mixins: [ResultMixin, PrivilegeMixin],

  provide() {
    return {
      baseView: this,
    };
  },

  data() {
    return {
      record: {},
      records: [],
      preload: false,
      loading: false,
      visible: false,
      sortable: false,
      breadcrumbItems: [],
      response: {
        refreshInterval: 5000,
        refreshTime: 0,
        refreshError: false,
      }, // Ken 2019-11-21 22:12 response中有refresh信息
      refreshTimer: null,
      isActivated: false,
      loadId: 0,
    };
  },

  computed: {
    ...mapGetters('selection', ['market']),
    ...mapGetters('profile', ['isAdmin', 'isSuper', 'isManager', 'isAdministrator']),
    readonly: function() {
      return this.hasRO && !this.hasRW;
    },
  },

  beforeCreate() {
    const market = this.$store.getters['selection/market'];
    this.operatorId = this.$store.state.profile.operatorId;
    this.sod = market.extra.sod;
    this.eod = market.extra.eod;
    this.oneWeekAgo = oneWeekAgoDatetime(market.extra.sod);
    this.halfYearAgo = halfYearAgoDatetime(market.extra.sod);
    this.tradingDate = formatStringDate(market.extra.tradingDate);
    this.prevTradingDate = formatStringDate(market.extra.prevTradingDate);
    this.historyTradingDate = formatStringDate(market.extra.historyTradingDate);
    this.handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        this.handleLoad();
      }
    };
  },

  beforeDestroy() {
    this.unload();
  },

  activated() {
    this.isActivated = true;
    this.handleLoad();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },

  deactivated() {
    this.unload();
  },

  methods: {
    ...mapMutations('access', ['pushMenu']),
    getCurrencyScale,

    handleLoad() {
      if (this.preload) {
        this.$nextTick(() => {
          this.load();
        });
      }
    },

    load(refresh = false) {
      const loadId = ++this.loadId;
      clearTimeout(this.refreshTimer);

      if (!refresh) {
        this.loading = true;
      }
      this.doLoad(refresh)
        .then(r => {
          if (loadId !== this.loadId) return; // Ken: drop old response [fix BAL-1148]

          this.response.refreshInterval = r.refreshInterval;
          this.response.refreshTime = r.refreshTime;
          this.response.refreshError = false;
          if (this.isError(r.data)) {
            this.showErrorMsg(r.data);
            this.response.refreshError = true;
          } else {
            this.parse(r);
            //
            if (r.refreshInterval && this.isActivated) {
              this.refreshTimer = setTimeout(() => {
                this.load(true);
              }, r.refreshInterval);
            }
          }
        })
        .catch(err => {
          if (this._isDestroyed) return;
          console.error(err);
          this.response.refreshError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Ken 2020-02-25 17:07 用于select组件值变化后load请求的发送
    delayLoad(load) {
      clearTimeout(this.refreshTimer);
      // on-select 回调的参数是选中的Option, 我们并不需要
      if (!_.isFunction(load)) {
        load = this.load;
      }
      // Ken 2020-02-25 17:09 将请求放到宏任务中, 目的: 在company组件改变后, 与之关联的book, symbol等组件的值也都更新完之后, 再执行宏任务
      // ($nextTick 微任务并不能实现此效果)
      setTimeout(load, 0);
    },

    sort(list) {
      this.loading = true;
      let doSort = this.doSort;
      if (doSort) {
        return doSort(list)
          .then(r => {
            if (this.isSuccess(r)) {
              if (!SUCCESS_MUTE) {
                this.$Message.success();
              }
            } else {
              this.showErrorMsg(r);
            }
          })
          .finally(() => {
            this.loading = false;
            this.load();
          });
      }
    },

    createModal,

    unload() {
      this.isActivated = false;
      clearTimeout(this.refreshTimer);
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
  },
});
