import { $fetch, $post, $put } from '@/services/axios';

export function add(data) {
  return $put(`company/product`, data);
}

export function fetch(data) {
  return $fetch(`company/products`, data);
}

export function validate(data) {
  return $post(`company/product/validate`, data);
}

export function edit(data) {
  return $post(`company/product/${data.id}`, data);
}
