<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="n-table-id">{{ $t('label.id') }}</th>
            <th class="companyId">{{ $t('label.company') }}</th>
            <th class="mobileType">{{ $t('label.mobileType') }}</th>
            <th class="storeId">{{ $t('label.storeProductId') }}</th>
            <th class="renewable">{{ $t('label.renewable') }}</th>
            <th class="text-right">{{ $t('label.basePrice') }}</th>
            <th class="premiumDays">{{ $t('label.premiumDays') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td v-enums:MobileType="row.mobileType"></td>
            <td>{{ row.storeProductId }}</td>
            <td><n-icon-boolean :value="row.renewable" /></td>
            <td class="n-text-right">
              <n-number :value="row.basePrice" :scale="row.currencyScale" :currency="row.baseCurrency" />
            </td>
            <td>{{ row.premiumDays | hyphen }}</td>
            <td class="n-button-box"><nb-modal @on-click="openModal(row)" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import BaseCompanyView from './view';
import ModalCompanyProduct from './ModalCompanyProduct';
import { fetch } from '@/api/company/company-product';
import {CURRENCY_SCALE_NAME} from "@/constant/enums";
import {Parser, setCurrencyScale} from "@/helpers/operators";

export default BaseCompanyView.extend({
  name: 'CompanyProduct',

  data() {
    return {
      preload: true,
      condition: {
        companyId: 0,
      },
    };
  },

  methods: {
    doLoad() {
      let companyId = this.condition.companyId;
      return fetch({ companyId });
    },

    parse(data) {
      this.records = Parser(data, [setCurrencyScale(CURRENCY_SCALE_NAME.CURRENCY)]);
    },

    openModal(model = {}) {
      this.createModal(ModalCompanyProduct, { model, on: this });
    },
  },
});
</script>
