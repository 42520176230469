<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- companyId -->
        <n-field-selection v-model="model.companyId" field-name="companyId" selection-name="company.company" label="company" :error="error.companyId" :disabled="isEdit || disabled" />
        <!-- mobileType -->
        <n-field-enum v-model="model.mobileType" field-name="mobileType" enum-name="MobileType" label="mobileType" :error="error.mobileType" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- abbreviation -->
        <n-field-text v-model="model.abbreviation" field-name="abbreviation" label="abbreviation" :error="error.abbreviation" :disabled="disabled" />
        <!-- storeProductId -->
        <n-field-text v-model="model.storeProductId" field-name="storeProductId" label="storeProductId" :error="error.storeProductId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- renewable -->
        <n-field-boolean v-model="model.renewable" label="renewable" :error="error.renewable" :disabled="disabled" />
        <!-- lifetimePlan -->
        <n-field-boolean v-model="model.lifetimePlan" label="lifetimePlan" :error="error.lifetimePlan" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- basePrice -->
        <n-field-decimal v-model="model.basePrice" label="basePrice" :disabled="disabled" />
        <!-- baseCurrency -->
        <n-field-selection v-model="model.baseCurrency" :error="error.baseCurrency" selection-name="market.currency" label="baseCurrency" :disabled="isEdit || disabled"  />
      </n-row-col2>
      <n-row-col2>
        <!-- premiumDays -->
        <n-field-id v-model="model.premiumDays" field-name="premiumDays" label="premiumDays" :error="error.premiumDays" :disabled="model.lifetimePlan" />
        <!-- language -->
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="isEdit || disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';
import { validate, add, edit } from '@/api/company/company-product';

export default NModal.extend({
  name: 'ModalCompanyProduct',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return this.isEdit ? edit(model) : add(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
